//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Recorder from 'js-audio-recorder';
export default {
	components: {
		player: () => import('./player')
	},
	data() {
		return {
			recorder: null,
			recordTime: 0,
			lastTime: 0,
			timer: null,
			url: {},
			startRecord: false,
			recordObj: null,
			upURL: '/Handler/ChatUploadFile.ashx',
			upLoad: false
		};
	},
	props: {
		maxRecorderTime: {
			type: Number,
			default: 60
		},
		size: {
			type: String,
			default: 'small'
		},
		showPlayer: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		getRecordTime() {
			let count = this.recordTime / 100;
			let second = count % 60 < 10 ? `0${parseInt(count % 60)}` : parseInt(count % 60) + '';
			let minute = Math.floor(count / 60) < 10 ? `0${Math.floor(count / 60)}:` : Math.floor(count / 60) + ':';
			return minute + second;
		},
		getSize() {
			switch (this.size) {
				case 'medium':
					return 64;
				case 'small':
					return 32;
				case 'mini':
					return 16;
			}
		}
	},
	methods: {
		initRecorder() {
			this.$emit('click');
			if (this.startRecord) {
				if (typeof plus === 'undefined') {
					this.closeH5();
				} else {
					this.closeAPP();
				}
				this.startRecord = false;
			} else {
				this.startRecord = true;
				if (typeof plus === 'undefined') {
					this.getStartH5();
				} else {
					this.getStartAPP();
				}
			}
		},
		send(fileName) {
			let downURL;
			if (process.env.NODE_ENV === 'development') {
				downURL = this.$url;
			} else {
				downURL = window.location.protocol + '//' + window.location.host;
			}
			downURL += this.upURL;
			let _this = this;
			let upload = plus.uploader.createUpload(
				downURL,
				{
					method: 'POST'
				},
				function(file, status) {
					if (status == 200) {
						_this.upLoad = false;
						let res = JSON.parse(file.responseText);
						if (res.length) {
							_this.url = res[0];
							_this.lastTime = parseInt(_this.recordTime / 100);
							let result = {
								url: res[0],
								duration: _this.lastTime
							};
							_this.$emit('audioURL', result);
						}
						// 请求本地文件系统对象
						plus.io.resolveLocalFileSystemURL(
							fileName,
							function(entry) {
								// remove this file
								entry.remove(
									function(entry) {},
									function(e) {
										console.log('删除录音文件出错：' + e.message);
									}
								);
							},
							function(error) {
								console.log('获取录音文件出错：' + error);
							}
						);
					}
				}
			);
			upload.addFile(fileName, { key: fileName });
			upload.addData('type', 'audio');
			this.upLoad = true;
			upload.start();
		},
		getStartAPP() {
			let audio = plus.audio.getRecorder();
			this.recordObj = audio;
			// console.log('设备录音支持的采用率:');
			// console.log(audio.supportedSamplerates);
			// console.log('设备录音支持的文件格式:');
			// console.log(audio.supportedFormats);
			//开始录音
			const success = success => {
				this.send(success);
			};
			const err = err => {
				clearInterval(this.time);
				this.startRecord = false;
				this.$notify({
					title: '录音失败',
					type: 'error',
					message: err.message
				});
			};
			this.beforStart();
			audio.record(
				{
					channels: 'mono',
					format: 'amr'
				},
				success,
				err
			);
		},
		getStartH5() {
			//开始录音
			this.recorder.start().then(
				() => {
					this.beforStart();
				},
				error => {
					this.$notify({
						type: 'error',
						message: '浏览器不支持录音'
					});
					this.startRecord = false;
					this.beforClose();
					console.log(`${error}`);
				}
			);
		},
		beforStart() {
			this.recordTime = 0;
			clearInterval(this.timer);
			this.timer = null;
			this.getTime();
		},
		beforClose() {
			this.startRecord = false;
			clearInterval(this.timer);
			this.timer = null;
		},
		closeH5() {
			this.beforClose();
			let blob = this.recorder.getWAVBlob();
			let audioFile = new File([blob], Date.now() + '.ogg', {
				type: 'audio/ogg',
				lastModified: Date.now()
			});
			this.uploadFile(audioFile);
		},
		closeAPP() {
			this.beforClose();
			this.recordObj.stop();
		},
		getTime() {
			if (this.timer == null) {
				this.timer = setInterval(() => {
					if (this.recordTime >= this.maxRecorderTime * 100) {
						if (typeof plus === 'undefined') {
							this.closeH5();
						} else {
							this.closeAPP();
						}
					} else {
						this.recordTime++;
					}
				}, 10);
			}
		},
		async uploadFile(file) {
			let formdate = new FormData();
			formdate.append('file', file);
			formdate.append('type', 'audio');
			try {
				this.upLoad = true;
				let res = await this.$http.post(this.upURL, formdate);
				this.upLoad = false;
				if (res.length) {
					this.url = res[0];
				}
				this.lastTime = parseInt(this.recorder.duration);
				let result = {
					url: res[0],
					duration: this.lastTime
				};
				this.$emit('audioURL', result);
			} catch (error) {
				console.log('聊天文件上传错误', error);
			}
		}
	},
	created() {
		this.recorder = new Recorder({
			sampleBits: 8
		});
	},
	destroyed() {
		this.recorder.destroy();
	}
};
